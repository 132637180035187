import React, { Component } from 'react'
import './Welcome.scss';
import marked from 'marked';

class Welcome extends Component {

  render() { 

    const text = "Hello, My name is Toan Nguyen. I am a full stack developer living in Surrey, BC, Canada. I have experience in web-development in both frontend and backend. I have expertise in `React`, `Vue`, `Nodejs`, `jQuery`, `PHP`, `Wordpress`, `MySql` and have decent knowledge in: `webpack`, `mongodb`, `ruby on rails`, `docker`, `nginx`, `firebase`...";

    return ( 
    <div className="welcome">
      <div dangerouslySetInnerHTML={{ __html: marked(text)}}></div>
      <div className="links">
        <a href="https://www.linkedin.com/in/toannguyen132" target="_blank"><i class="fab fa-linkedin-in"></i></a>
        <a href="mailto:n.minhtoan@gmail.com"><i class="fas fa-envelope"></i></a>
      </div>
    </div>
    );
  }
}
 /*
 <p>
        Hello, My name is Toan Nguyen. I am a full-stack developer living in Surrey, BC, Canada. I have experience in web-development in both frontend and backend. I have expertise in React, Vue, Nodejs, jQuery, PHP, Wordpress, MySql and have decent knowledge in: webpack, mongodb, ruby on rails, docker, nginx, firebase...
      </p>
      <p>
        My email: <a href="mailto:n.minhtoan@gmail.com">n.minhtoan@gmail.com</a><br/>
        Linkedin: <a href="https://www.linkedin.com/in/toannguyen132/">www.linkedin.com/in/toannguyen132/</a> 
      </p>*/
export default Welcome;