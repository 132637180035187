import React, { Component } from 'react';
import './App.scss';
import Welcome from './components/Welcome';
import {Helmet} from "react-helmet";

class App extends Component {
  render() {
    return (
      <div className="App">
        <Helmet>
          <meta charSet="utf-8" />
          <title>Toan Nguyen | a Full Stack Web Developer</title>
        </Helmet>
        <Welcome></Welcome>
      </div>
    );
  }
}

export default App;
